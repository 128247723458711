.section {

  &.with-overlay {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: -moz-linear-gradient(top, rgba(45,62,88,0.20) 0%, rgba(0, 0, 0, 0.70) 100%);
      background: -webkit-linear-gradient(top, rgba(45,62,88,0.20) 0%, rgba(0, 0, 0, 0.70) 100%);
      background: linear-gradient(to bottom, rgba(45,62,88,0.20) 0%, rgba(0, 0, 0, 0.70) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2D3E58', endColorstr='#000000',GradientType=0 );
    }
  }
  &.intro-section {
    background: {
      //image: url($images-path + 'intro-bg.jpg');
      position: center;
      repeat: no-repeat;
      size: cover;
    };

    .container {
      position: relative;
      z-index: 3;
    }
    .link-to-bottom {
      z-index: 4;
    }

    &:before {
      z-index: 2;
    }
  }
  &.about-section {
    padding-top: 10rem;
    padding-bottom: 8rem;

    h1 {
      font-size: 7.2rem;
      text-align: center;
      color: $color-5;
      margin-bottom: 2rem;

      @media all and (max-width: 767px) {
        font-size: 7rem;
      }

      @media all and (max-width: 575px) {
        font-size: 4.6rem;
      }
    }

    h2 {
      width: 80%;
      font-size: 3.5rem;
      color: $color-6;
      text-align: center;
      margin: 0 auto 6rem;

      @media all and (max-width: 767px) {
        font-size: 4rem;
      }

      @media all and (max-width: 575px) {
        font-size: 3.6rem;
      }
    }

    h3 {
      color: $color-5;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 8rem;
    }

    h6 {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 9rem;
    }
  }
  &.how-we-start-section {
    padding: 7rem 0;
    background-color: #efefef;

    h3 {
      text-align: center;
      text-transform: uppercase;
    }
  }
  &.text-section {
    padding: 5rem 0;
    background: {
      image: url($images-path + 'text-section-bg.png');
      position: center;
      repeat: no-repeat;
      size: cover;
    };

    h3, h5 {
      text-align: center;
      color: $color-7;
      line-height: 1.55;
    }
    h3 {
      font: {
        size: 3rem;
        weight: $main-font-weigth-n;
      };
    }
    h5 {
      margin-top: 2rem;
      font-weight: $main-font-weigth-l;
    }

    &.text-section-3 {
      padding: 15rem 0;
      background: {
        attachment: fixed;
        image: url($images-path + 'text-section-bg-2.jpg');
      };

      &:before {
        background-image: -moz-linear-gradient(top, rgba(45,62,88,0.70), rgba(0, 0, 0, 0.80));
        background-image: -webkit-linear-gradient(top, rgba(45,62,88,0.70), rgba(0, 0, 0, 0.80));
        background-image: -o-linear-gradient(top, rgba(45,62,88,0.70), rgba(0, 0, 0, 0.80));
        background-image: -ms-linear-gradient(top, rgba(45,62,88,0.70), rgba(0, 0, 0, 0.80));
        background-image: linear-gradient(top, rgba(45,62,88,0.70), rgba(0, 0, 0, 0.80));
        filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#cc2d3e58', endColorstr='#000000');
      }

      .container, .row {
        height: 100%;
      }

      h1 {
        font: {
          size: 7.2rem;
          weight: $main-font-weigth-b;
        };
        line-height: 1.17;
        letter-spacing: 1px;
        text-align: center;
        color: $color-7;
        padding: 2.4rem 0 4rem;
      }

      p {
        font: {
          size: 2.2rem;
          weight: $main-font-weigth-l;
        };
        color: $color-7;
        text-align: center;
        line-height: 1.55;
      }

      .buttons-container {
        display: flex;
        justify-content: center;
        margin: 7rem 0 0;
      }
    }
  }
  &.examples-section {
    padding: 9rem 0;
    background-color: #efefef;
  }
  &.strategy-section {
    padding: 7rem 0;
    height: 100vh;
    min-height: 60rem;
    background: {
      attachment: fixed;
      image: url($images-path + 'car-bg.jpg');
      position: center;
      repeat: no-repeat;
      size: cover;
    };

    .container, .row {
      height: 100%;
    }

    &:before {
      background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#000000', endColorstr='#2D3E58');
    }
  }
  &.cards-section {
    padding: 7rem 0;
    background-color: #efefef;

    &.white-section {
      background-color: #fff;
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 3rem;
    }

    h5 {
      text-align: center;
      line-height: 1.55;
      font-size: 2rem;
      font-weight: $main-font-weigth-l;
      margin-bottom: 7rem;
    }
  }
  &.support-section {
    padding: 7rem 0;
    height: 100vh;
    min-height: 60rem;
    background: {
      attachment: fixed;
      image: url($images-path + 'types-of-digital-marketing.jpg');
      position: center;
      repeat: no-repeat;
      size: cover;
    };

    .container, .row {
      height: 100%;
    }

    &:before {
      background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      background-image: linear-gradient(top, rgba(0, 0, 0, 0.80), rgba(45,62,88,0.60));
      filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#000000', endColorstr='#2D3E58');
    }
  }
  &.business-section {
    padding: 7rem 0;

    h3 {
      margin-bottom: 10rem;
      text-align: center;
    }

    .business-img {
      max-width: 100%;
    }
  }
  &.task-section {
    padding: 10rem 0;
    min-height: 100vh;
    background: {
      attachment: fixed;
      image: url($images-path + 'task-bg.png');
      position: center;
      repeat: no-repeat;
      size: cover;
    };

    h3 {
      font: {
        size: 4.2rem;
        weight: $main-font-weigth-b;
      };
      color: $color-7;
      text-align: center;
      line-height: 1.23;
      margin-bottom: 4rem;
    }

    p {
      font: {
        size: 2rem;
        weight: $main-font-weigth-l;
      };
      line-height: 1.55;
      margin-bottom: 5rem;
      color: $color-7;
      text-align: center;
    }

    &:before {
      background-image: -webkit-linear-gradient(top, rgba(45,62,88,0.20), rgba(0, 0, 0, 0.70));
      background-image: -o-linear-gradient(top, rgba(45,62,88,0.20), rgba(0, 0, 0, 0.70));
      background-image: -ms-linear-gradient(top, rgba(45,62,88,0.20), rgba(0, 0, 0, 0.70));
      background-image: linear-gradient(top, rgba(45,62,88,0.20), rgba(0, 0, 0, 0.70));
      filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#cc2d3e58', endColorstr='#000000');
    }
  }
  &.team-section {
    padding: 10rem 0;

    h3 {
      margin-bottom: 10rem;
    }
  }
  &.companies-section {
    padding: 12rem 0;
    background-color: #efefef;

    h3 {
      margin-bottom: 4rem;
      font: {
        size: 4.2rem;
      };
      line-height: 1.23;
    }

    p {
      max-width: 56rem;
      margin: 0 auto 10rem;
      font: {
        size: 2.4rem;
        weight: $main-font-weigth-l;
      };
      line-height: 1.5;
      color: $color-5;
    }
  }
  &.map-section {
    position: relative;
    padding: 10rem 0;

    @media all and (max-width: 991px) {
      padding: 0;
    }
  }
}