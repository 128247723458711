.business-text {

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 2rem;

      span, p {
        font: {
          family: $primary-font-family;
          weight: $main-font-weigth-l;
          size: 1.6rem;
        };
        line-height: 1.5;
        color: $color-5;
      }

      .title {
        font-weight: $main-font-weigth-b;
      }
    }
  }

  & > p {
    font: {
      size: 1.5rem;
      weight: $main-font-weigth-l;
    };
    line-height: 1.55;
    color: $color-5;
  }

  .buttons-container {
    margin: 6rem 0 4rem;

    .btn {
      text-transform: none;
    }
  }
}