// Path's
$images-path: '../img/';
$icons-path: '../img/icons/';
$uploaded-images-path: '../uploads/';

// Color palette
$color-1: #fff;
$color-2: #f0f0f0;
$color-3: #d4d4d4;
$color-4: #000;
$color-5: #a50715;
$color-6: #73030b;
$color-7: #fff;
$color-8: #777;
$color-9: #ff4a4a;

// General
$primary-font-family: 'Roboto', sans-serif;
$main-font-weigth-n: 400;
$main-font-weigth-l: 300;
$main-font-weigth-b: 700;
$main-font-weigth-m: 500;
$main-font-style-n: normal;
$main-font-style-i: italic;

// Animation
$base-transition-speed: 0.5s;
$base-transition-delay: 0s;