.blockquote {
  margin-bottom: 0;
  background-color: $color-6;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;

  p {
    font: {
      size: 2.5rem;
      style: $main-font-style-i;
      weight: $main-font-weigth-l;
    };
    color: $color-7;
    line-height: 1.2;
    margin-bottom: 3rem;
  }

  .author {
    font: {
      size: 1.6rem;
      weight: $main-font-weigth-b;
    };
    color: #dbdbdb;
    line-height: 1.15;
    margin: 0 0 0 auto;
    opacity: 0.6;
  }
}