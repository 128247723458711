.intro-block {
  width: 100%;
  height: 100vh;
  min-height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 10rem;
    color: $color-1;
    text-align: center;

    @media all and (max-width: 991px) {
      font-size: 9rem;
    }
    @media all and (max-width: 767px) {
      font-size: 8rem;
    }
  }

  h4 {
    margin: 3rem 0 5rem;
    color: $color-2;
  }

  .buttons-container {
    display: flex;
    justify-content: center;

    @media all and (max-width: 575px) {
      flex-wrap: wrap;
    }

    .btn {
      @media all and (min-width: 576px) {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
      @media all and (max-width: 575px) {
        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}