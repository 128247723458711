.modal {
  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;

    &:focus {
      outline: none;
    }
  }
}
.modal-dialog {
  max-width: 56rem;

  &.modal-lg {
    max-width: 96rem;
  }
}
.modal-intro-img {
  img {
    width: 100%;
  }
}
.modal-body {
  padding: 4rem 4.5rem;

  h4 {
    font: {
      size: 3.6rem;
      weight: 600;
    };
    text-align: center;
    margin-bottom: 1.1rem;
    line-height: 1.23;
    color: $color-5;
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    color: $color-5;
    line-height: 1.55;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }

  ul {
    margin: 0 0 3rem;

    li {
      font: {
        family: $primary-font-family;
        size: 1.6rem;
        weight: $main-font-weigth-l;
      };
      color: $color-5;
      line-height: 1.55;
    }
  }

  .form {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
.success-modal {
  max-width: 40rem;

  .modal-content {
    border: none;
  }
  .modal-close-btn {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 9;

    svg {
      path {
        fill: $color-6;
        @include ak-transition(all, $base-transition-speed, $base-transition-delay);
      }
    }

    &:hover {
      svg {
        path {
          fill: $color-8;
        }
      }
    }
  }
  .modal-body {
    .icon-container {
      display: flex;

      svg {
        margin: 1.5rem auto;
      }
    }
  }
}