.price-container {
  margin: 1.5rem 0;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 0.5rem;
    font: {
      size: 2rem;
      weight: $main-font-weigth-m;
    };
    line-height: 1.35;
    color: $color-5;

    &.old-price {
      color: #bdbdbd;
      text-decoration: line-through;
    }
  }
}