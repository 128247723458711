.team-list {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 50%;
    padding-right: 10rem;
    display: flex;
    margin-bottom: 7rem;

    @media all and (min-width: 576px) {
      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    @media all and (max-width: 991px) {
      padding-right: 3rem;
    }
    
    @media all and (max-width: 575px) {
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .avatar-container {
      width: 10rem;
      height: 10rem;
      margin-right: 3rem;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      flex: none;

      img {
        max-width: 100%;
      }
    }

    .title {
      display: flex;
      flex-direction: column;

      span {
        display: inline-block;
      }

      .name {
        font: {
          size: 1.6rem;
          weight: 600;
        };
        color: $color-6;
        line-height: 1.35;
        margin-top: 1.4rem;
      }
      .position {
        font: {
          size: 1.4rem;
          weight: 300;
        };
        line-height: 1.55;
        color: #666;
        margin-top: 0.3rem;
      }
    }

    p {
      font-size: 1.5rem;
      line-height: 1.55;
      font-weight: $main-font-weigth-l;
      margin-top: 0.8rem;
    }
  }
}