html {
  font-size: 10px;

  @media all and (max-width: 1199px) {
    font-size: 9px;
  }
  @media all and (max-width: 767px) {
    font-size: 8px;
  }
}

html, body {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1240px;
  padding: 0 15px;
  margin: 0 auto;
}