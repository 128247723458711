.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font: {
    family: $primary-font-family;
  };
  @include ak-transition(all, $base-transition-speed, $base-transition-delay);

  &:focus {
    box-shadow: none;
  }

  &.btn-primary {
    background-color: $color-3;;
    border: 0.1rem solid $color-3;
    color: $color-4;

    &:hover {
      background-color: darken($color-3, 10);
      border-color: darken($color-3, 10);
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      background-color: darken($color-3, 10);
      border-color: darken($color-3, 10);
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      background-color: darken($color-3, 10);
      border-color: darken($color-3, 10);
      box-shadow: none;
      color: $color-4;
    }
  }

  &.btn-secondary {
    border: 0.1rem solid $color-3;
    background-color: transparent;
    color: $color-3;

    &:hover {
      background-color: $color-3;
      color: $color-4;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $color-3;
      color: $color-4;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      background-color: $color-3;
      border-color: $color-3;
      color: $color-4;
      box-shadow: none;
    }
  }

  &.btn-third {
    background-color: $color-6;
    //border-radius: 0.5rem;
    border-radius: 0;
    height: 3.5rem;
    padding: {
      left: 1.5rem;
      right: 1.5rem;
    };
    color: $color-7;
    font: {
      size: 1.3rem;
      weight: $main-font-weigth-b;
    };

    &:hover {
      background-color: lighten($color-6, 10);
    }
  }

  &.btn-fourth {
    width: 13.5rem;
    height: 4.5rem;
    background-color: $color-9;
    color: $color-7;
    //border-radius: 2.2rem;
    border-radius: 0;
    font: {
      size: 1.4rem;
      weight: $main-font-weigth-b;
    };
    text-transform: uppercase;

    &:hover {
      background-color: lighten($color-9, 10);
    }
  }

  &.btn-fifth {
    width: 27rem;
    height: 6rem;
    background-color: $color-6;
    //border-radius: 3rem;
    border-radius: 0;
    font-size: 1.6rem;
    font-weight: $main-font-weigth-m;
    color: $color-7;
    text-transform: uppercase;

    &:hover {
      background-color: lighten($color-6, 10);
    }
  }

  &.btn-form {
    background-color: $color-5;
    height: 5.6rem;
    //border-radius: 0.7rem;
    border-radius: 0;
    padding: 0 2rem;
    color: $color-7;
    width: 100%;
    font: {
      size: 1.6rem;
      weight: 700;
    };

    &:hover {
      background-color: lighten($color-5, 10);
    }
  }

  &.btn-lg {
    width: 25rem;
    height: 6rem;
    //border-radius: 3rem;
    border-radius: 0;
    //border-width: 0.3rem;
    border-width: 0.1rem;
    font: {
      size: 1.6rem;
      weight: $main-font-weigth-b;
    };
  }
}