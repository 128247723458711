.tasks-list {
  margin: 0;
  padding: 0 2rem 0 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media all and (max-width: 767px) {
    padding: 0;
  }

  li {
    font: {
      family: $primary-font-family;
      size: 2rem;
      weight: $main-font-weigth-l;
    };
    color: $color-5;
    text-transform: uppercase;
    line-height: 1.55;
    height: 6rem;
    display: flex;
    align-items: center;
    border-left: 0.3rem solid $color-6;
    padding-left: 2rem;
    background-color: $color-2;

    @media all and (max-width: 767px) {
      margin: 0.7rem 0;
      height: auto;
      padding: {
        top: 1rem;
        bottom: 1rem;
      };
    }
  }
}