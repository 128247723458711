.owl-carousel {
  position: relative;

  @media all and (max-width: 1400px) {
    box-sizing: border-box;
    padding: 0 4rem;
  }

  &.examples-carousel {

    @media all and (max-width: 475px) {
      padding: 0;
    }

    .owl-stage {
      display: flex;
      align-items: stretch;
    }

    .owl-item {
      display: flex;
    }
    .item {
      padding: 3rem 1rem;
    }
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;

    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      outline: none;

      svg {
        width: 2rem;
        height: 3.2rem;

        path {
          fill: $color-5;
        }
      }

      &.disabled {
        opacity: 0.3;
      }
    }

    .owl-prev {
      left: -5rem;

      @media all and (max-width: 1400px) {
        left: 0;
      }
    }
    .owl-next {
      right: -5rem;

      @media all and (max-width: 1400px) {
        right: 0;
      }
    }
  }

  &.companies-carousel {

    .owl-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 15rem;
    }

    .item {
      padding: 3rem;

      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  }
}