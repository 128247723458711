.link-to-bottom {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  display: inline-block;
  width: 3.8rem;
  height: 1.8rem;
  margin-left: -1.9rem;
  animation: jumpingDown 2s 1s infinite;

  svg {
    path {
      fill: #fff;
    }
  }
}