.form {

  .form-group {
    margin-bottom: 2rem;
  }

  .form-control {
    height: 5.6rem;
    padding: 0 2rem;
    //border-radius: 7px;
    border-radius: 0;
    background-color: $color-7;
    color: $color-5;
    font: {
      family: $primary-font-family;
      size: 1.6rem;
    };
    line-height: 1.33;
    border: 1px solid #c9c9c9;

    &::placeholder {
      opacity: 0.7;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  textarea {
    &.form-control {
      padding: 1.7rem 2rem 0;
      height: 10rem;
    }
  }
}