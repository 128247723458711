@keyframes jumpingDown {
  0% {
    transform: translateY(-0.5rem);
  }
  25% {
    transform: translateY(0.4rem);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0.4rem);
  }
  100% {
    transform: translateY(-0.5rem);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}