.card {
  background-color: $color-7;
  border: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.25), 0 3px 5px rgba(0, 0, 0, 0.22);
  border-radius: 0;
  @include ak-transition(all, $base-transition-speed, $base-transition-delay);

  &:hover {
    text-decoration: none;
    box-shadow: 0 15px 25px rgba(0,0,0,0.25), 0 6px 10px rgba(0,0,0,0.22);

    .image-container {
      filter: grayscale(0);
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 30rem;
    overflow: hidden;
    filter: grayscale(100%);
    @include ak-transition(all, $base-transition-speed, $base-transition-delay);

    img {
      min-height: 100%;
      min-width: 100%;
      @include ak-position-abs-vh-center;
    }
  }
  .card-body {
    padding: 2.7rem 3rem;
  }
  .card-footer {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0 3rem 2.1rem;
  }
  .price {
    display: inline-block;
    margin-top: 2rem;
    font: {
      family: $primary-font-family;
      weight: $main-font-weigth-n;
      size: 1.6rem;
    };
    color: $color-5;
  }

  h4 {
    font-size: 2.4rem;
    font-weight: $main-font-weigth-b;
    line-height: 1.35;
    text-align: center;
    color: $color-5;
  }

  h5 {
    font: {
      size: 2rem;
      weight: $main-font-weigth-b;
    };
    color: $color-5;
    line-height: 1.35;
  }

  p {
    font: {
      size: 1.4rem;
      weight: $main-font-weigth-l;
    };
    line-height: 1.55;
    color: $color-5;
  }

  ul {
    margin: 0;
    padding: 0 0 0 2rem;

    li {
      font: {
        family: $primary-font-family;
        weight: $main-font-weigth-l;
        size: 1.4rem;
      };
      color: $color-5;
      line-height: 1.55;
    }
  }

  .buttons-container {
    margin-bottom: 2rem;

    .btn {
      margin: 0 auto;
    }
  }

  &.card-type-1 {
    .image-container {
      width: 100%;
      height: 30rem;

      img {
        width: auto;
        min-width: 100%;
        min-height: 100%;
      }
    }

    p {
      margin-top: 1rem;
    }
  }
  &.card-type-2 {
    padding: 6rem;
    max-width: 36rem;
    margin: auto;
    height: auto;

    @media all and (max-width: 575px) {
      padding: 4rem;
    }

    p {
      text-align: center;
      margin-top: 0.5rem;
    }
  }
  &.card-type-3 {
    background-color: $color-7;

    .image-container {
      height: 40rem;

      img {
        max-width: 100%;
        max-height: 100%;
        min-width: auto;
        min-height: auto;
      }
    }

    h4 {
      text-align: left;
      margin-bottom: 1rem;
    }

    .btn {
      font-size: 1.4rem;
      width: 20rem;
      height: 4.5rem;
    }
  }
  &.contacts-card {
    position: relative;
    max-width: 46rem;
    width: 100%;
    z-index: 99;
    background: white;
    min-height: 300px;
    padding: 6rem;
    left: 50%;
    transform: translateX(calc(-100% - 10rem));

    @media all and (max-width: 991px) {
      left: 0;
      transform: translateX(0);
      min-height: auto;
    }

    @media all and (max-width: 575px) {
      padding: 2.5rem;
    }

    h4 {
      font: {
        size: 2.6rem;
        weight: 500;
      };
      text-align: left;
      margin-bottom: 3rem;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        a {
          font: {
            family: $primary-font-family;
            size: 1.8rem;
            weight: 300;
          };
          line-height: 1.55;
          color: $color-5;
          text-decoration: none;
        }

        &:hover {
          color: $color-6;
        }
      }
    }
  }
}