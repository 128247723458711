.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media all and (max-width: 991px) {
    position: relative;
    height: 45rem;
  };
}