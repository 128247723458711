.starting-steps {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 7rem;

  @media all and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .item {
    position: relative;
    width: calc(100% / 4);

    @media all and (max-width: 767px) {
      width: 50%;
    }

    .icon-container {
      display: flex;
      width: 100px;
      height: 100px;
      margin: 0 auto 3rem;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    p {
      text-align: center;
      padding: 0 3.5rem;
      font: {
        size: 1.5rem;
        weight: $main-font-weigth-m;
      };
      line-height: 1.5;
    }

    &:before {
      display: none;
      content: '';
      width: 6rem;
      height: 2.8rem;
      position: absolute;
      right: 0;
      top: 4rem;
      transform: translateX(50%);
      background: {
        image: url($icons-path + 'arrow.svg');
        position: center;
        repeat: no-repeat;
        size: contain;
      };
    }

    @media all and (min-width: 768px) {
      &:not(:last-child){
        &:before {
          display: block;
        }
      }
    }

    @media all and (max-width: 767px) {
      &:not(:nth-child(2n)) {
        display: block;
      }
    }
  }
}