h1, h2, h3, h4,
h5, h6, span, a, p {
  font-family: $primary-font-family;
  line-height: 1;
  margin: 0;
}

h1 {
  font-size: 6.2rem;
  font-weight: $main-font-weigth-b;

  @media all and (max-width: 767px) {
    font-size: 5.6rem;
  }
}

h3 {
  font-size: 3.6rem;
  font-weight: $main-font-weigth-m;

  @media all and (max-width: 767px) {
    font-size: 3.2rem;
  }
}

h4 {
  font-size: 2.2rem;
  font-weight: $main-font-weigth-n;
}

h5 {
  font-size: 2rem;
  font-weight: $main-font-weigth-n;
}

h6 {
  font-size: 1.4rem;
}

p {
  font-size: 1.4rem;
}

a {
  color: $color-1;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.text-unerline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}